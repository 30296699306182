<template>
<div>


<div class="grid gap-4 grid-cols-6 pt-5">
    <div class="px-4 py-4 text-center bg-yellow rounded">
      <span class=" uppercase font-semibold text-sm pb-5">Students</span><hr>
      <span class="uppercase font-semibold text-4xl">273</span>
    </div>
    <div class="px-4 py-4 text-center bg-green rounded">
      <span class=" uppercase font-semibold text-sm ">Subjects</span><hr>
      <span class="uppercase font-semibold text-4xl ">{{ total.subjects }}</span>
    </div>
    <div class="px-4 py-4 text-center bg-purple rounded ">
      <span class=" uppercase font-semibold text-sm ">Courses</span><hr>
      <span class="uppercase font-semibold text-4xl">{{ total.courses }}</span>
    </div>
</div>
    <div class="mt-16 height overflow-y-scroll no-scrollbar">
            <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                <tr>
                    <th scope="col" class="pl-9 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    ID
                    </th>
                    <th scope="col" class="pl-9 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                    </th>
                    <th scope="col" class="pl-9 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Course
                    </th>
                    <th scope="col" class="pl-9 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Father Name
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">edit</span>
                    </th>
                </tr>
                </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="student in students" :key="student.id">

                  <td class="px-4 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ student.enrollNumber }}
                      </div>
                    </div>
                  </div>
                </td>

                <td class="px-4 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ student.name }}
                      </div>
                    </div>
                  </div>
                </td>

                <td class="px-4 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ student.course}}
                      </div>
                    </div>
                  </div>
                </td>

                <td class="px-4 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ student.father_name}}
                      </div>
                    </div>
                  </div>
                </td>

                <td class="px-4 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <router-link :to="'/edit/'+ student.id" class="text-indigo-600 hover:text-indigo-900">
                     Edit
                  </router-link>
                </td>
              </tr>
            </tbody>
            </table>
        </div>
      </div>
</template>

<script>
import { mapState } from 'vuex'
    export default {
        name:'Home',
        computed:{
            ...mapState(['students','total'])
        },
    }
</script>

<style>
.bg-yellow{
  background-color: #EFEE9D;
}
.bg-green{
  background-color: #D1EAA3;
}
.bg-purple{
  background-color: #DBC6EB;
}
.bg-skyblue{
  background-color: #ABC2E8;
}
</style>